<template>
  <div>
  <v-row v-if="field.typeComponent && field.typeComponent === 'package'">
    <CustomSelectPackageComponent
      :options="field.data.values"
      :showFieldIccid="true"
      :showFieldConta="true"
      :showFieldAparelho="true"
      :desactiveFields="desactiveFields"
      :default_date="field.default_date ? field.default_date : ''"
      :default_hour="field.default_hour ? field.default_hour : ''"
      :page="page"
      :cols="4"
      @updateValues="updateValuesCustomSelectPackageComponent"
    ></CustomSelectPackageComponent>
  </v-row>
  <v-row v-if="field.typeComponent && field.typeComponent === 'plan'" class="mx-auto">
    <v-col cols="4" v-for="(plan, index) in field.data[0]" :key="index">
      <v-card class="mx-auto">
        <v-card-title class="primary white--text mb-4" :title="index">
          <div class="headerClass">
            {{ index }}
          </div>
        </v-card-title>
        <v-card-text class="col-md-12">
          <v-col class="col-md-12">
            <v-skeleton-loader
              v-if="loading"
              type="card-heading, paragraph"
            ></v-skeleton-loader>
          </v-col>
          <div class="col-md-12" v-if="!loading && plan.linha_referencia">
            <template>
              <v-alert border="left" color="indigo" dark dense class="mb-0">
                Linha referência:
              </v-alert>
              <v-list>
                <LineReferenceComponent
                    :label="'Usuário'"
                    :label_value="plan.linha_referencia.value ? plan.linha_referencia.value.nome_completo : 'Não existe'"
                />
                <v-divider />
                <LineReferenceComponent
                    :label="'Linha'"
                    :label_value="plan.linha_referencia.value ? '('+plan.linha_referencia.value.ddd+') '+plan.linha_referencia.value.nr_linha : 'Não existe'"
                />
                <v-divider />
                <v-divider />
              </v-list>
            </template>
          </div>

          <v-autocomplete
            dense
            outlined
            :label="plan.pessoa.label"
            v-model="plan.pessoa.value"
            :items="persons.data"
            item-text="nome_completo"
            item-value="id_pessoa"
            :readonly="plan.pessoa.readonly"
            v-if="!loading && plan.pessoa"
            @change="saveSelected(index)"
          >
          </v-autocomplete>

          <v-autocomplete
              dense
              outlined
              :label="plan.ddd.label"
              v-model="plan.ddd.value"
              :items="listDdds"
              :readonly="plan.ddd.readonly"
              item-text="code"
              v-if="!loading && plan.ddd"
              :rules="!!plan.ddd.required ? required : []"
              @change="saveSelected(index, plan)"
          >
            <template v-slot:selection="data">
              <template v-if="data.item.code && data.item.region">
                {{ data.item.code }} - {{ data.item.region }}
              </template>
              <template v-else>
                {{ data.item.code }}
              </template>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                      v-html="data.item.code"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                      v-html="data.item.region"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <v-text-field
              dense
              outlined
              v-model="plan.iccid.value"
              :label="plan.iccid.label"
              :readonly="plan.iccid.readonly"
              v-if="!loading && !desactiveComponentNewIccid && plan.iccid && page !== 'validate'"
              @change="saveSelected(index)"
          />

          <v-combobox
              v-if="!loading && !desactiveComponentNewIccid && plan.iccid && page === 'validate'"
              label="Buscar ou criar novo ICCID"
              v-model="selectedChip[index]"
              item-text="iccid"
              item-value="iccid"
              :rules="rulesIccid"
              :items="iccids[index]"
              outlined
              dense
              :search-input.sync="searchInputs[index]"
              :loading="loading"
              @change="saveSelectedChip(index)"
              :filter="filterIccid"
              @keydown="onKeyDown($event, index)">
            <template #no-data>
              <v-list-item v-if="searchInputs[index] != null && searchInputs[index].length > 0" @click="createNewIccid(index, searchInputs[index])">
                <span class="subheading mr-2">Criar ICCID</span>{{ searchInputs[index] }}
              </v-list-item>
            </template>

            <template #selection="data">
              <template v-if="selectedChip[index] != null && Object.keys(selectedChip[index]).length > 0 && data.item.ddd && data.item.iccid">
                (DDD - {{ data.item.ddd }}) {{ data.item.iccid }}
              </template>
              <template v-if="selectedChip[index] != null && Object.keys(selectedChip[index]).length > 0 && !data.item.ddd && data.item.iccid">
                (HIBRIDO) {{ data.item.iccid }}
              </template>
            </template>

            <template #item="data">
              <v-list-item-content v-if="data.item.ddd">
                <v-list-item-title>(DDD - {{ data.item.ddd }}) {{ data.item.iccid }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-if="!data.item.ddd">
                <v-list-item-title>(HIBRIDO) {{ data.item.iccid }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-combobox>

          <v-autocomplete
              v-model="plan.conta.value"
              :items="listAccounts"
              item-text="cod_conta"
              outlined
              dense
              :readonly="plan.conta.readonly"
              :label="plan.conta.label"
              v-if="!loading && plan.conta"
              :rules="!!plan.conta.required ? required : []"
              @change="saveSelected(index, plan)"
              :filter="filterConta"
          >
            <template v-slot:selection="data">
              <template>
                (DDD - {{ data.item.ddd }}) - {{ data.item.cod_conta }} - {{ data.item.nome_operadora }}
              </template>
            </template>

            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title>
                    (DDD - {{ data.item.ddd }}) - {{ data.item.cod_conta }} - {{ data.item.nome_operadora }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.item.descricao }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <v-autocomplete
              dense
              outlined
              :label="plan.aparelho.label"
              v-model="plan.aparelho.value"
              :items="imeis"
              item-text="aparelho"
              item-value="id_aparelho"
              :readonly="plan.aparelho.readonly"
              v-if="!loading && plan.aparelho"
              @change="saveSelected(index)"
              :filter="filterAparelho"
          >
            <template v-slot:selection="data">
              <template>
                {{ data.item.imei }} - {{ data.item.fabricante }} {{ data.item.modelo }}
              </template>
            </template>

            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.imei }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.item.fabricante }} {{ data.item.modelo }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <v-text-field
            v-model="plan.linha_ativar.value"
            :label="plan.linha_ativar.label"
            outlined
            dense
            @change="onChangeFone(index)"
            v-if="!loading && plan.linha_ativar"
            v-mask="mask"
            :rules="telephoneRules[index]"
          />
          <v-divider
            class="mb-5"
            v-if="plan.linha_ativar && page === 'interaction'
            "
          ></v-divider>

          <!-- Data e hora -->
          <SuspendLineDateFields
            :type="field"
            :index="index"
            :default_label_date="'Data de Ativação'"
            :default_label_hour="'Hora de Ativação'"
            :default_date="field.default_date"
            :default_hour="field.default_hour"
            @changeDateValues="updateDate"
            v-if="page === 'interaction' && !loading"
          />
        </v-card-text>
      </v-card>
      <custom-new-single-chip-form-component
          v-if="!desactiveComponentNewIccid"
          v-model="isChipDialogOpen[index]"
          :iccid="createIccid"
          :listIccid="iccids[indexNewIccid]"
          :index="index"
          @changeSelectedChip="updateSelectedChip($event)"
          @close="onCloseDialog($event)"
      />
    </v-col>
  </v-row>
  </div>
</template>
<script>
import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
import SuspendLineDateFields from "../customFieldsComponents/SuspendLineDateFields.vue";
import CustomNewSingleChipFormComponent from "../forms/CustomChangeChipComponent/CustomNewSingleChipFormComponent.vue";
import axios from "@/axios.config";
import LineReferenceComponent from "../validate_forms/LineReferenceComponent.vue";
import CustomSelectPackageComponent from "@/components/forms/CustomSelectPackageComponent.vue";

export default {
  components: {
    CustomSelectPackageComponent,
    LineReferenceComponent, CustomNewSingleChipFormComponent, SuspendLineDateFields },
  props: ["field", "page"],
  data() {
    return {
      validationTelephones: "",
      mask: ["(",/\d/,/\d/,") ",/\d/,/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/],
      telephoneRules: [],
      value: [],
      loading: true,
      haveDate: false,
      desactiveFields: false,
      default_date: '',
      default_hour: '',
      imeis: [],
      desactiveComponentNewIccid: false,
      selectedChip: [],
      rulesIccid: [],
      listDdds: [],
      listAccounts: [],
      iccids: [],
      isChipDialogOpen: [],
      search: '',
      searchInputs: [],
      indexNewIccid: '',
      createIccid: '',
      haveHour: false,
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`],
      saveValid: false
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    try {
      if (this.page === 'interaction' || this.page === 'approval') {
        this.desactiveFields = true;
      }
      await Promise.all([
        this.loadPersons(),
        this.loadImeis(),
        this.loadAccounts(),
      ]);
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }

    for (let i in this.field.data[0]) {
      let ddd = this.field.data[0][i]['ddd'].value;
      await this.fetchChips(ddd, i);

      this.selectedChip[i] = this.field.data[0][i]['iccid'].value ? this.field.data[0][i]['iccid'].value : '';
    }
    this.imeis = this.getImeis();
    this.value = this.field;
    this.listDdds = this.field.ddds;
    this.loading = false;
    await this.changeValues();
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("persons", ["persons"]),
    ...mapState("solicitation", ["solicitation_validate_values"])
  },
  methods: {
    ...mapActions("persons", ["loadPersons"]),
    ...mapActions("solicitation", ["setActivateNewLine", "loadImeis", "loadAccountsJoin", "setAutomationComponentValues"]),
    ...mapActions("interaction", ['setAutomationComponentFields']),
    ...mapGetters("solicitation", ["getImeis", "getAccounts"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapMutations({ setSave: "interaction/setSave" }),

    createNewIccid: function (index, iccid) {
      this.isChipDialogOpen[index] = true;
      this.createIccid = iccid;
      this.indexNewIccid = index;
    },

    loadAccounts: async function () {
      this.loading = true;
      await this.loadAccountsJoin().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.listAccounts = this.getAccounts();
      this.loading = false;
    },

    async updateValuesCustomSelectPackageComponent(data) {
      this.field.data.values = data;
      await this.changeValues();
    },

    changeValues: async function () {
      if (this.page !== 'interaction') {
        this.SET_FORM_EMAIL();
        let values_fields = {...this.solicitation_validate_values};
        values_fields.activate_new_line = this.field;
        this.setAutomationComponentValues({
          automation: "activate_new_line",
          data: values_fields
        });
        this.SET_FORM_EMAIL(this.field);
      } else {
        await this.setAutomationComponentFields({
          automation: "activate_new_line",
          data: this.field.data
        });
      }
    },

    onChangeFone: async function (index) {
      this.telephoneRules[index] = [
        v =>
            /^\([0-9]{2}\) [0-9]?[0-9]{4}-[0-9]{4}$/.test(v) ||
            "Numero de telefone invalido"
      ];

      await this.saveSelected(index);
    },

    saveSelectedChip: function (index) {
      this.field.data[0][index]['iccid'].value = this.selectedChip[index].iccid;
      this.changeValues();
    },

    filterAparelho: function (item, queryText) {
      const regExp = new RegExp(queryText, 'i')

      return (
          regExp.test(item.imei) || regExp.test(item.fabricante) || regExp.test(item.modelo)
      );
    },

    filterConta: function (item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return (
          regExp.test(item.cod_conta) || regExp.test(item.ddd) || regExp.test(item.nome_operadora) || regExp.test(item.descricao)
      );
    },

    filterIccid: function (item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return regExp.test(item.iccid)
    },

    onKeyDown: function (e, index) {
      /**
       * Workaround: Quando enter é pressionado componente combobox
       * joga valor do search para um campo mágico e acaba com todo o fluxo.
       * Dessa forma esvazia de forma forçada o campo de busca e povoa o campo
       * para criação de iccid
       */
      if (e.keyCode === 13 /* ENTER */) {
        this.createIccid = this.searchInputs[index]
        this.searchInputs[index] = undefined
      }
    },

    updateSelectedChip: function (event) {
      if (!event.new_chip) {
        this.$swal(
            "Oops..", "Ocorreu um erro ao salvar novo ICCID",
            "error"
        );
        return;
      }
      this.field.data[0][event.index]['iccid'].value = event.new_chip.iccid;
      this.selectedChip[event.index] = event.new_chip;
      this.changeValues();
    },

    onCloseDialog: function (event) {
      this.isChipDialogOpen[event.index] = false;
      this.createIccid = undefined
      this.searchInputs[event.index] = undefined
    },

    fetchChips: async function (ddd, index) {
      this.loading = true
      await axios.get('solicitation/chips/available', {
        params: {ddd}
      })
          .then((res) => {

            if (res.data.success) {
              this.iccids[index] = res.data.data
            }
          })
          .finally(() => {
            this.loading = false
          })
    },

    updateDate(selectedValue) {
      let hourValue = {
        type: "initHour",
        value: selectedValue.finishHour
      };

      let dateValue = {
        type: "initDate",
        value: selectedValue.initialDate
      };

      this.field.data[0][selectedValue.index]['initDate'] = dateValue;
      this.field.data[0][selectedValue.index]['initHour'] = hourValue;

      this.validateDate(selectedValue);
      this.updateState(
        this.field.data[0][selectedValue.index],
        selectedValue.index
      );
    },

    validateDate(selectedValue) {
      const today = new Date();
      const selectedDate = selectedValue.initialDate;

      let formatedSelectedDate = new Date(selectedDate);
      formatedSelectedDate.setHours(formatedSelectedDate.getHours() + 12);

      let formatedToday = today.toLocaleDateString("pt-BR", {
        timeZone: "UTC"
      });

      if (today >= formatedSelectedDate) {
        this.saveValid = true;
        this.setSave(this.saveValid);
      } else {
        this.$swal(
          "Oops..",
          `A data não pode ser maior que ${formatedToday}, mude a data para salvar!`,
          "error"
        );
        this.saveValid = false;
        this.setSave(this.saveValid);
      }
    },

    updateState(valueToUpdate, index) {
      this.setActivateNewLine(this.field.data);
    },

    getDefaultDate() {
      let defaultFinishDate = new Date();
      defaultFinishDate.setDate(defaultFinishDate.getDate());
      this.loading = false;
      return defaultFinishDate
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    },

    async saveSelected(index) {
      if (this.page === "interaction") {
        this.setActivateNewLine(this.field.data);
      } else {
        this.SET_FORM_EMAIL(this.field);
        await this.fetchChips(this.field.data[0][index]['ddd'].value, index);
        await this.changeValues();
      }
    }
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
