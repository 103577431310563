<template>
  <div>
    <v-card class="mx-auto" outlined>
      <v-card-title>
        Histórico de Interações
        <v-spacer></v-spacer>
        <v-btn
          v-if="is_adm"
          outlined
          color="primary"
          @click="updateShowNotification"
        >
          <v-icon :title="titleButtonEye" v-if="!showNotification">mdi-eye-off-outline</v-icon>
          <v-icon :title="titleButtonEye" v-else>mdi-eye-outline</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="text--primary">
        <div v-if="loadingInteraction" class="text-center ma-5">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-if="!loadingInteraction" class="chat-container">
          <div
            v-for="interaction in interactions.data"
            :key="interaction.id"
            class="mb-6"
            :class="{ own: !interaction.reply }"
          >
            <v-card v-if="showHistoric(interaction.status_id)">
              <v-card-title class="primary white--text">
                {{ interaction.status_name }}
                <v-spacer></v-spacer>
                <v-btn icon dark v-if="showBtnDelete(interaction.status_id)" :loading="loadingDeleteBtn" @click="deleteInteraction(interaction.id)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <template>
                  <v-simple-table v-if="interaction.solicitation_name">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-if="interaction.user" class="text-left">Autor</th>
                          <th v-if="interaction.requester && interaction.status_id !== SOLICITATION_STATUS.ENVIO_NOTIFICACAO" class="text-left">Solicitante</th>
                          <th v-if="interaction.solicitation_name" class="text-left">Nome da Solicitação</th>
                          <th v-if="interaction.approver" class="text-left">Aprovador</th>
                          <th v-if="interaction.created_at" class="text-left">Data de criação</th>
                          <th v-if="interaction.emailSendProvider && interaction.emailSendProvider.send_at" class="text-left">Data envio e-mail</th>
                          <th v-if="interaction.fields && interaction.fields.protocolo" class="text-left">Protocolo</th>
                          <th v-if="interaction.fields && interaction.fields.tempo_atendimento" class="text-left">Tempo de atendimento</th>
                          <th v-if="interaction.fields && interaction.fields.data_previsao" class="text-left">Data de previsão</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td v-if="interaction.user">{{ interaction.user }}</td>
                          <td v-if="interaction.requester && interaction.status_id !== SOLICITATION_STATUS.ENVIO_NOTIFICACAO">{{ interaction.requester }}</td>
                          <td v-if="interaction.solicitation_name">{{ interaction.solicitation_name }}</td>
                          <td v-if="interaction.approver">{{ interaction.approver }}</td>
                          <td v-if="interaction.created_at">{{ interaction.created_at_format }}</td>
                          <td v-if="interaction.emailSendProvider && interaction.emailSendProvider.send_at">{{ interaction.emailSendProvider.send_at }}</td>
                          <td v-if="interaction.fields && interaction.fields.protocolo">{{ interaction.fields.protocolo }}</td>
                          <td v-if="interaction.fields && interaction.fields.tempo_atendimento">{{ interaction.fields.tempo_atendimento }}</td>
                          <td v-if="interaction.fields && interaction.fields.data_previsao">{{ interaction.fields.data_previsao }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <v-divider></v-divider>

                <show-notification-interaction-component
                  v-if="showComponentNotification(interaction.status_id)"
                  :subject="interaction.fields.subject"
                  :to="interaction.fields.to"
                  :body="interaction.fields.body"
                  :condition="interaction.fields.condition"
                  :action="interaction.fields.action_type"
                ></show-notification-interaction-component>

                <v-row class="ml-1 mt-3">
                  <v-col class="description-text" v-if="interaction.fields && interaction.fields.descricao">
                    <span class="mr-2">Descrição:</span>
                    <span class="text">{{ interaction.fields.descricao }}</span>
                  </v-col>
                </v-row>
                <v-divider v-if="!interaction.emailSendProvider"></v-divider>
                <v-textarea
                    v-if="interaction.historic_values && !interaction.emailSendProvider"
                    class="description-size"
                    label="Campos"
                    outlined
                    disabled
                    flat
                    hide-details
                    v-model="interaction.historic_values"
                    readonly
                ></v-textarea>

                <v-row v-if="interaction.values && interaction.values.from">
                  <v-col>
                    <span class="mr-4">De</span>
                    <v-chip pill> {{ interaction.values.from }} </v-chip>
                  </v-col>
                </v-row>
                <v-row v-if="interaction.values && interaction.values.subject">
                  <v-col>
                    <span>
                      {{ interaction.values.id_email }} -
                      {{ interaction.values.subject }}
                    </span>
                    <span>{{ interaction.values.date }}</span>
                  </v-col>
                </v-row>
                <v-divider v-if="interaction.body"></v-divider>
                <v-row v-if="interaction.body">
                  <v-col v-if="interaction.type_body === 'text'">
                    <v-textarea
                      label="Conteúdo do e-mail"
                      solo
                      flat
                      hide-details
                      auto-grow
                      v-model="interaction.body"
                      readonly
                    ></v-textarea>
                  </v-col>
                  <v-col v-else>
                    <ShadowHtml :content="interaction.body" ></ShadowHtml>
                  </v-col>
                </v-row>

                <v-row v-if="interaction.emailSendProvider && interaction.emailSendProvider.subject_email">
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-text-field
                      label="Assunto"
                      v-model="interaction.emailSendProvider.subject_email"
                      :readonly="true"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="interaction.emailSendProvider">
                  <v-col md="12" cols="12">
                    <quill-editor
                        :content="interaction.emailSendProvider.message_email"
                        :options="editorOption"
                    />
                  </v-col>
                </v-row>

                <v-row class="ml-4" v-if="interaction.has_file === true">
                  <span class="align description-text">Anexos:</span>
                  <v-chip small class="ml-2" v-for="file in interaction.arquivos" :key="file.id" draggable>
                    <a @click="downloadFile(file)">
                      {{ file.name }}
                    </a>
                  </v-chip>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { SOLICITATION_STATUS } from "@/helpers/constants.js";
import ShowNotificationInteractionComponent from "@/components/interaction/showNotificationInteractionComponent.vue";
import ShadowHtml from "@/components/interaction/shadowHtmlComponent.vue";
export default {
  components: {
    ShadowHtml,
    ShowNotificationInteractionComponent,
    quillEditor,
  },
  props: {
    is_adm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      titleButtonEye: 'Exibir histórico de Notificações',
      loadingInteraction: true,
      loadingDeleteBtn: false,
      dialog: false,
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
    };
  },

  async mounted() {
    this.$store.commit("interaction/SET_SHOW_NOTIFICATION", false);
    await this.loadData();
  },

  computed: {
    SOLICITATION_STATUS() {
      return SOLICITATION_STATUS
    },
    ...mapState("interaction", ["interactions","showNotification"]),
  },
  methods: {
    ...mapActions("interaction", ["delete","loadInteractions"]),
    updateShowNotification() {
      this.$store.commit("interaction/SET_SHOW_NOTIFICATION", !this.showNotification);
    },
    showHistoric(status_id) {
      return status_id !== SOLICITATION_STATUS.ENVIO_NOTIFICACAO ? true : this.showNotification;
    },
    showComponentNotification(status_id) {
      return status_id === SOLICITATION_STATUS.ENVIO_NOTIFICACAO && this.showNotification;
    },
    showBtnDelete(status) {
      return (status === '0d663491-f911-4a4b-811e-f5c802caaaa6' || status == 'd0ad29e0-fc41-40b8-b5e3-872719f349dd') ? true : false;
    },
    async loadData() {
      this.loadingInteraction = true;
      let solicitation_id = null;
      if (this.$route.params.solicitation_id) {
        solicitation_id = this.$route.params.solicitation_id;
      } else if (this.$route.params.id) {
        solicitation_id = this.$route.params.id;
      }
      await this.loadInteractions({
        solicitation: solicitation_id
      });
      if (this.interactions.success === false) {
        await this.$swal("Oops...", this.interactions.message, "error");
      }
      this.loadingInteraction = false;
    },
    downloadFile(file) {
      this.downloadFiles(file);
    },
    async deleteInteraction(interaction_id) {
      this.loadingDeleteBtn = true;
      await this.$swal({
        icon: "warning",
        text: `Tem certeza que deseja excluir essa Interação?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#445E93",
        confirmButtonText: "Sim"
      }).then(async result => {
        if (result.value) {
          await this.delete(interaction_id).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
        }
      });
      this.loadingDeleteBtn = false;
      await this.loadData();
    },
    ...mapActions("interaction", ["downloadFiles"])
  }
};
</script>

<style scoped>
.btn-mais {
  color: white;
  font-size: 12px;
}

.card-text {
  font-weight: bold;
  font-size: 16px;
}

.description-text {
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.text {
  font-size: 13px;
  font-weight: 400;
}

.align {
  display: flex;
  align-items: center;
}

.chat-container {
  overflow-y: auto;
  padding: 10px;
  border-radius: 10px;
}

.chat-container .titulo {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}

.chat-container .content {
  padding: 1px 10px 3px 10px;
  background-color: #00838f;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-width: 50%;
  word-wrap: break-word;
  color: white;
}

.content {
  margin: 0 15px;
}
.content p {
  margin: 0px;
}

.message {
  margin-bottom: 5px;
}

.divider-margin {
  padding: 8px;
}

.message.own {
  text-align: right;
}

.message.own .content {
  background-color: #445e93;
  color: white;
}

.small-date {
  font-size: 11px;
  color: #546e7a;
}

.titulo {
  margin-bottom: 10px;
}

.description-size {
  font-size: 0.875rem;
  line-height: 1.5;
}

.titulo-dialog {
  text-align: center;
  font-size: 20px;
}
@media (max-width: 480px) {
  .chat-container .content {
    max-width: 60%;
  }
}
</style>
