<template>
  <v-row class="mx-auto">
    <v-col cols="12" v-if="page !== 'interaction' && page !== 'approval'">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">{{ dynamicLabel }}</v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-for="(lineSelected, lineIndex) in lineSelect"
      :key="lineIndex"
      :cols="lineSelect.length > 1 ? 6 : 12"
    >
      <v-card outlined class="mx-auto">
        <v-card-title
          class="primary white--text"
          v-if="page !== 'interaction'"
        >
          <div class="headerClass">
            {{ lineSelectedTitle(lineSelect[lineIndex].id) }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeLine(lineIndex)"
            class="mx-3 mt-2"
            v-if="lineSelect.length > 1 && page !== 'interaction' && page !== 'approval'"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row
            v-for="(changeChipLine, index) in lineSelected.fields"
            :key="index"
          >
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                required
                label="Linha"
                v-model="lineSelect[lineIndex].id"
                :items="lines.data"
                item-text="nr_linha"
                item-value="id_linha"
                :rules="lineRules"
                :filter="customFilter"
                @input="updateSelectedLine($event, lineIndex)"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-text-field
                label="ICCID novo SIM Card"
                outlined
                dense
                v-model="changeChipLine.value"
                v-if="
                  page === 'interaction' ||
                  page === 'validate' ||
                  page === 'approval'
                "
                @input="updateValues()"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
export default {
  props: ["field", "page"],
  data() {
    return {
      lineSelect: [],
      loading: true,
      dynamicLabel: "",
      show: false,
      lineRules: [v => !!v || "Linha é obrigatório"]
    };
  },
  async mounted() {
    this.lineSelect = this.field.lines;
    let params = {};
    if (this.page === 'approval') {
      params = { showAll: true }
    }

    await this.loadLines(params).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    this.loading = false;
    this.updateValues();

    if (this.selected_solicitation_validate_fields.data) {
      this.selected_solicitation_validate_fields.data.map(step => {
        if (step.name == "change_chip") {
          this.dynamicLabel = step.label;
        }
      });
    }
  },
  computed: {
    ...mapState("solicitation", ["selected_solicitation_validate_fields"]),

    ...mapState("packages", ["lines"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("packages", ["loadLines", "loadLinePackages"]),
    ...mapActions("solicitation", [
      "setChangeChipComponentValues",
      "updateSolicitationValidateChangeChip"
    ]),
    ...mapMutations({ setModel: "forms/setModelCancelLine" }),
    async clickAddNewLine() {
      const newLine = {
        fields: [{ value: "" }]
      };
      this.lineSelect = [...this.lineSelect, newLine];
    },
    lineSelectedTitle(id) {
      if (this.lines?.data?.length) {
        const selectedItem = this.lines?.data.find(item => item.id_linha === id);
        if (selectedItem) {
          return `${selectedItem.ddi} (${selectedItem.ddd}) ${selectedItem.nr_linha}`;
        }
      }
      return "Linha não identificada";
    },
    async updateSelectedLine(lineSelected, index) {
      this.lineSelect[index].id = lineSelected;

      this.show = this.show ? false : lineSelected;
      this.show = false;
      this.updateValues();
    },

    updateValues() {
      if (this.page === 'approval') {
        return;
      }
      let cancelLineList = [];
      this.lineSelect.map(line => {
        line.fields.map(lineValue => {
          let newLine = {
            id: line.id,
            fields: [
              {
                name: "iccid",
                type: "iccid",
                label: "ICCID novo SIM Card",
                value: lineValue.value
              }
            ]
          };
          cancelLineList.push(newLine);
        });
      });
      this.setChangeChipComponentValues(cancelLineList);
    },

    closeLine(index) {
      if (index > -1) {
        this.lineSelect.splice(index, 1);
      }
      this.updateValues();
    },

    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
